import React, { Fragment } from 'react';
import { FormattedNumber } from 'react-intl';

import DataRow from 'components/Shared/DataRow/DataRow';
import Hr from 'components/Shared/Hr/Hr';
import InvoiceStatus from 'components/Shared/InvoiceStatus/InvoiceStatus';
import { breakpoints, colors, fontSize } from 'styles/cp.js';

export interface Props {
  paymentMethod?: any;
  maskedAccountNumber?: any;
  authorizationID?: any;
  amount?: any;
  balanceAmount?: any;
  isPartiallyPaid?: any;
  currency?: any;
  CFTooltip?: any;
  beforePay?: any;
  separator?: boolean;
}

const PaymentInfo: React.FC<Props> = ({
  paymentMethod,
  maskedAccountNumber,
  authorizationID,
  amount,
  balanceAmount,
  isPartiallyPaid,
  currency,
  beforePay,
  separator = true,
}: Props) => {
  const amountWithoutCF = amount;

  return (
    <Fragment>
      <ul className="payment-info">
        {!!amount && amount > 0 && (
          <DataRow
            idValue="INVOICE_TOTAL"
            defaultMessage="Total"
            bold={true}
            color={colors.black}
            value={<FormattedNumber value={amount} style="currency" currency={currency} />}
          />
        )}
        {!!amount &&
          amount > 0 &&
          ((balanceAmount > 0 && !beforePay) ||
            balanceAmount - (amountWithoutCF || amount) > 0) && (
            <Hr marginTop={10} marginBottom={0} borderColor={colors.offWhite} opacity={1} />
          )}
        {paymentMethod && (
          <DataRow
            idValue="PAYFLOW_PAYMENT_METHOD"
            defaultMessage="Payment method"
            value={paymentMethod}
            maskedAccount={maskedAccountNumber}
          />
        )}
        {authorizationID && (
          <DataRow
            idValue="AUTHORIZATION_ID"
            defaultMessage="Authorization ID"
            value={authorizationID}
          />
        )}
        {separator && authorizationID && <Hr marginTop={0} marginBottom={0} />}
        {isPartiallyPaid &&
          !!balanceAmount &&
          balanceAmount > 0 &&
          (beforePay ? (
            <DataRow
              idValue="INVOICE_SUMMARY_INVOICE_OUTSTANDING_BALANCE"
              defaultMessage="Outstanding balance"
              value={
                <FormattedNumber
                  value={balanceAmount - (amountWithoutCF || amount)}
                  style="currency"
                  currency={currency}
                />
              }
              pos={'wide'}
            />
          ) : (
            <DataRow
              idValue="INVOICE_SUMMARY_INVOICE_OUTSTANDING_BALANCE"
              defaultMessage="Outstanding balance"
              value={<FormattedNumber value={balanceAmount} style="currency" currency={currency} />}
            />
          ))}
        {beforePay && balanceAmount - (amountWithoutCF || amount) > 0 && (
          <DataRow
            idValue="INVOICE_SUMMARY_INVOICE_OUTSTANDING_BALANCE"
            defaultMessage="Outstanding balance"
            value={
              <FormattedNumber
                value={balanceAmount - (amountWithoutCF || amount)}
                style="currency"
                currency={currency}
              />
            }
            pos={'wide'}
          />
        )}
        {isPartiallyPaid && <InvoiceStatus idValue="INVOICE_SUMMARY_PARTIALLY_PAID" />}
      </ul>
      {/*language=SCSS*/}
      <style jsx>{`
        .payment-info {
          padding: 0px;
          margin: 0px;
          @media (max-width: ${breakpoints.md}) {
            max-width: 400px;
            margin: 5px auto;
          }
          @media (max-width: ${breakpoints.sm}) {
            max-width: 576px;
          }
        }
        .space-between {
          display: flex;
          justify-content: space-between;
        }
        .flex-row {
          flex-direction: row;
          margin: 6px 0px;

          .label {
            font-family: AvenirNextforINTUIT-Medium;
            cursor: text;
            font-size: ${fontSize.xs};
            color: ${colors.gray};
            display: flex;
          }
        }
        .right {
          text-align: right;
        }
      `}</style>
    </Fragment>
  );
};

export default PaymentInfo;
